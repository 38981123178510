import React, { useEffect } from 'react'
import Box from 'common/Box'
import useFormView from 'hooks/UseFormView'
import Typography from 'common/Typography'
import { isSm } from 'constants/media-queries'

const PartyForm = ({
    emitter, stateHandler, isPrimary, index, id, label, fields,
}) => {
    const {
        validateInput: validatePartyInput,
        formView: {
            email,
            firstName,
            lastName,
            taxId,
            dateOfBirth,
            usCitizen,
            allowLogin,
        },
    } = useFormView({ fields })

    useEffect(() => {
        const submitPartyInput = () => {
            const { valid, input } = validatePartyInput()
            // boolean components return 'yes' or 'no' for backwards compatibility with steps
            const isCitizen = input.usCitizen === 'yes'
            const isAllowLogin = input.allowLogin === 'yes'

            if (valid) {
                stateHandler.party(index, {
                    ...input, isPrimary, usCitizen: isCitizen, allowLogin: isAllowLogin,
                })
            } else {
                stateHandler.error()
            }
        }

        emitter.on('submitInput', submitPartyInput, { id })
        return () => emitter.removeListener('submitInput', submitPartyInput, { id })
    })

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {isPrimary ? <Typography variant='h6'>{`Primary ${label}`}</Typography> : <Typography variant='h6'>{`Secondary ${label} ${index + 1}`}</Typography>}
            {email}
            <Box sx={{ display: 'flex', gap: 4, [isSm]: { flexDirection: 'column' } }}>
                {firstName}
                {lastName}
            </Box>
            <Box sx={{ display: 'flex', gap: 4, [isSm]: { flexDirection: 'column' } }}>
                {taxId}
                {dateOfBirth}
            </Box>
            {usCitizen}
            {allowLogin}
        </Box>
    )
}

export default PartyForm
