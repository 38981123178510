import * as React from 'react'
import * as DateFns from 'date-fns'
import { DataGridPro } from '@mui/x-data-grid-pro'
import numeral from 'numeral'

import LinearProgress from 'common/LinearProgress'
import DataGridFooter from 'common/DataGridFooter'
import Typography from 'common/Typography'
import { GridNoneFound } from 'common/NoneFound'
import {
    removeReactFromColumns,
    addReactToColumns,
    syncVisibilityModel,
} from 'common/DataGrid/utils/column-utils'
import useQuery from 'delv/hooks/use-query.js'
import useLocalStorage from 'hooks/use-local-storage'
import PageLayout from 'layouts/page-layout'
import patchReducer from 'reducers/patch-reducer'
import AccountFragment from 'components/account/Fragment'
import CurrencyFragment from 'fragments/currency-fragment'
import RoundFragment from 'components/round/Fragment'
import StepFragment from 'fragments/step-fragment'
import ShortStepFragment from 'fragments/short-step-fragment'
import DataGridToolbar from 'common/DataGridToolbar'
import Distribution from 'components/distribution/index.js'
import Asset from 'components/asset/index.js'

import { CurrencyCell, DateCell, NumberCell } from 'common/DataGridCell'
import {
    selectInvestmentRound,
    selectInvestmentStatus,
    selectInvestmentPurchasePrice,
    selectInvestmentInitial,
    selectInvestmentActiveQualificationInfo,
    selectInvestmentPaidInCapital,
} from 'selectors/investment-selectors'

const CurrencyQuery = `
    allCurrencies(condition: { code: "USD" }) {
        nodes {
            ${CurrencyFragment}
        }
    }
`
const priceFragment = `
    purchasePrice {
        id
        price
    }
`

// Purchase price within price fragment is subject to row level security,
// Agent role is not allowed to view it, so we conditionally include.
const DistributionsQuery = (accountType) => `
allDistributions{
    nodes {
        ${Distribution.fragment.toString()}
        ${accountType === 'agent' ? '' : priceFragment}
        accountByInvestorId {
            ${AccountFragment.toString()}
        }

        stepByStepId {
            ${StepFragment}
            shortSteps {
                nodes {
                    ${ShortStepFragment}
                }
            }
        }
        allocationByAllocationId {
            id
            name
            roundByRoundId {
                totalCapitalDeployed
                ${RoundFragment.toString()}
                assetByAssetId {
                    ${Asset.fragment.toString()}
                    accountByIssuerId {
                        ${AccountFragment.toString()}
                    }
                }
            }
        }
    }
}`

const BREADCRUMBS = [
    {
        display: 'Dashboard',
        link: '/dashboard',
        active: false,
    },
    {
        display: 'Distributions',
        link: '/distributions',
        active: true,
    },
]

const columnDefaults = {
    hideable: true,
    flex: 1,
    minWidth: 100,
    filterable: true,
}

const numberColumnDefaults = {
    ...columnDefaults,
    align: 'right',
}

const initialColumns = [
    {
        ...columnDefaults,
        field: 'investor',
        headerName: 'Investor',
        minWidth: 150,
        renderCell: ({ value }) => (
            <Typography variant='subtitle2'>
                {value}
            </Typography>
        ),
    },
    {
        ...columnDefaults,
        field: 'email',
        headerName: 'Investor Email',
        minWidth: 150,
    },
    {
        ...numberColumnDefaults,
        field: 'createdOn',
        headerName: 'Created On',
        renderCell: DateCell,
    },
    {
        ...columnDefaults,
        field: 'status',
        headerName: 'Status',
    },
    {
        ...columnDefaults,
        field: 'progress',
        headerName: 'Progress',
    },
    {
        ...columnDefaults,
        field: 'activeStep',
        headerName: 'Active Step',
    },
    {
        ...columnDefaults,
        field: 'asset',
        headerName: 'Asset',
        minWidth: 150,
    },
    {
        ...columnDefaults,
        field: 'round',
        headerName: 'Round',
        minWidth: 150,
    },
    {
        ...columnDefaults,
        field: 'allocation',
        headerName: 'Allocation',
        minWidth: 150,
    },
    {
        ...numberColumnDefaults,
        field: 'isPaidInFull',
        headerName: 'Paid',
        type: 'boolean',
        minWidth: 75,
    },
    {
        ...numberColumnDefaults,
        field: 'units',
        headerName: 'Units',
        renderCell: NumberCell,
    },
    {
        ...numberColumnDefaults,
        field: 'price',
        headerName: 'Unit Price',
        renderCell: CurrencyCell,
    },
    {
        ...numberColumnDefaults,
        field: 'total',
        headerName: 'Total Price',
        renderCell: CurrencyCell,
    },
    {
        ...columnDefaults,
        field: 'setup',
        headerName: 'Account Setup',
        type: 'boolean',
        minWidth: 75,
    },
]

const format = (data) => {
    const allDistributions = data.allDistributions.nodes
    const rows = allDistributions.map((investment) => {
        const investmentStatus = selectInvestmentStatus({ investment })
        const total = selectInvestmentInitial({ investment })
        const price = selectInvestmentPurchasePrice({ investment })
        const investor = investment.accountByInvestorId
        const createdOn = investment.createdOn
        const round = selectInvestmentRound({ investment })
        const allocation = investment.allocationByAllocationId
        const asset = round.assetByAssetId
        const isPaidInFull = numeral(total).format('0.00') === numeral(selectInvestmentPaidInCapital({ investment })).format('0.00')
        const {
            qualificationActivityDisplay,
            qualificationName,
        } = selectInvestmentActiveQualificationInfo({ investment })

        return {
            id: investment.id,
            investor: investor.name,
            setup: investor.setup,
            email: investor.email,
            allocation: allocation.name,
            asset: asset.name,
            round: round.name,
            status: investmentStatus,
            progress: qualificationActivityDisplay,
            activeStep: qualificationName,
            units: investment.amount,
            isPaidInFull,
            price,
            total,
            createdOn,
        }
    })

    return { rows }
}

const initialColumnVisibilityModel = {
    asset: true,
}

const Distributions = (props) => {
    const queries = `{${[CurrencyQuery, DistributionsQuery(props.account.type)].join('\n')}}`

    const [querySelection, setQuerySelection] = React.useReducer(patchReducer, { onboarding: true, pending: true })
    const { data, loading } = useQuery(queries, { format, networkPolicy: 'network-only' })
    const [density, setDensity] = useLocalStorage('row-density', 'compact')
    const [columns, setColumns] = useLocalStorage('distributions-columns', initialColumns, {
        serialize: removeReactFromColumns,
        deserialize: addReactToColumns(initialColumns),
    })
    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage('distributions-column-visibility', initialColumnVisibilityModel, {
        deserialize: syncVisibilityModel(initialColumnVisibilityModel),
    })

    const rows = data?.rows || []

    const toggleQuerySelection = (key) => setQuerySelection({ [key]: !querySelection[key] })
    const resetGrid = () => {
        setColumns(initialColumns)
        setColumnVisibilityModel(initialColumnVisibilityModel)
    }

    return (
        <PageLayout breadcrumbs={BREADCRUMBS}>
            <DataGridPro
                disableColumnResize={false}
                disableColumnPinning
                onColumnOrderChange={(e) => {
                    const target = e.targetIndex
                    const old = e.oldIndex
                    const newColumns = [...columns]
                    newColumns[target] = columns[old]
                    newColumns[old] = columns[target]
                    setColumns(newColumns)
                }}
                rows={rows}
                columns={columns}
                loading={loading}
                rowThreshold={0}
                density={density}
                onColumnWidthChange={(e) => {
                    const newColumns = [...columns].map((col) => (
                        col.field === e.colDef.field
                            ? ({
                                ...col,
                                flex: undefined,
                                width: e.colDef.width,
                            }) : col
                    ))
                    setColumns(newColumns)
                }}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                onStateChange={(params) => {
                    if (params.density.value !== density) {
                        setDensity(params.density.value)
                    }
                }}
                sx={{
                    border: 'none',
                    backgroundColor: '#ffffff',
                    '& .Mui-selected': {
                        background: 'transparent !important',
                    },
                }}
                components={{
                    LoadingOverlay: LinearProgress,
                    Footer: DataGridFooter,
                    NoRowsOverlay: GridNoneFound,
                    Toolbar: DataGridToolbar,
                }}
                componentsProps={{
                    noRowsOverlay: {
                        children: 'No Distributions Found',
                    },
                    toolbar: {
                        querySelection,
                        toggleQuerySelection,
                        resetGrid,
                        filter: true,
                        export: true,
                        printOptions: {
                            disableToolbarButton: true,
                        },
                        csvOptions: {
                            fileName: `distributions_vertalo_${DateFns.format(new Date(), 'MM_dd_yyyy')}`,
                        },
                    },
                    footer: {
                        rows,
                        totalCount: rows.length,
                    },
                }}
                columnVisibilityModel={columnVisibilityModel}
            />
        </PageLayout>
    )
}

export default Distributions
