import * as React from 'react'
import * as DateFns from 'date-fns'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MUIDatePicker from '@mui/lab/DatePicker'

import LocalizationProvider from '@mui/lab/LocalizationProvider'

import TextField from 'common/TextField'

const formatDate = (value) => {
    const numberDate = value.replace(/[^0-9]+/g, '')

    if (value === numberDate) {
        if (numberDate.length === 3) {
            return `${numberDate.substring(0, 2)}/${numberDate.substring(2)}`
        }
    }

    if (value.length === numberDate.length + 1) {
        if (numberDate.length === 5) {
            return `${numberDate.substring(0, 2)}/${numberDate.substring(2, 4)}/${numberDate.substring(4)}`
        }
    }

    if (value.length >= 10) {
        return value.substring(0, 10)
    }

    return value
}

const DatePicker = ({
    'data-testid': testID, onChange, onBlur, helperText, errorMessage, error, key, value, name, fullWidth, required, ...props
}) => {
    const selectValue = (e) => {
        if (e?.target) {
            return formatDate(e.target.value)
        }

        return e && new Date(e).toString() !== 'Invalid Date'
            ? DateFns.format(new Date(e), 'MM/dd/yyyy')
            : e
    }

    const handleFactory = (callBack) => (e) => {
        const newValue = selectValue(e)
        callBack({
            target: {
                value: newValue,
                name,
            },
        })
    }

    const handleChange = handleFactory(onChange)
    const handleBlur = handleFactory(onBlur)

    const newValue = value && new Date(value).toString() !== 'Invalid Date' && new Date(value).toISOString() === value
        ? DateFns.format(new Date(value), 'MM/dd/yyyy')
        : value || ''

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MUIDatePicker
                clearable
                renderInput={({ error: incorrectErrror, value: incorrectValue, ...params }) => (
                    <TextField
                        data-testid={testID}
                        helperText={error && errorMessage ? errorMessage : helperText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={error}
                        fullWidth={fullWidth}
                        required={required}
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            value: newValue,
                        }}
                    />
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                disableMaskedInput
                onError={() => undefined}
                key={key}
                {...props}
                value={newValue}
            />
        </LocalizationProvider>
    )
}

export default DatePicker
