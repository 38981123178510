import conflict from './TypeMapConflict.js'

const pluralize = require('pluralize')
// const blacklistFields = ['node', 'id', 'nodeId', 'nodes', 'edges']

const blacklistTypes = [
    null,
    'Node',
    'Int',
    'String',
    'Cursor',
    'UUID',
    'Boolean',
    'PageInfo',
    'Float',
    'Mutation',
    'ID',
    'Datetime',
    '__Type',
    '__Schema',
    '__Directive',
    '__EnumValue',
    '__Field',
    '__InputValue',
    'BigFloat',
    'BitString',
]

class TypeMap {
    constructor() {
        this.map = new Map()
        this.loadTypes()
    }

    loadTypes = (data) => {
        // eslint-disable-next-line
        for (const key in data) {
            const value = data[key]
            this.map.set(key, value)
        }
        this.map.set('totalCount', 'Integer')
    }

    loadIntrospection = (queryResult) => {
        // eslint-disable-next-line
        queryResult.__schema.types.forEach((type) => {
            if (
                type.fields
                && !blacklistTypes.includes(type.name)
                && !type.name.endsWith('Payload')
            ) {
                type.fields.forEach((field) => {
                    let typeName = field.type.name
                    if (typeName === null) {
                        typeName = field.type.ofType.name
                    }
                    if (!blacklistTypes.includes(typeName)) {
                        this.map.set(field.name, typeName)
                    }
                })
            }
        })
        const exportData = {}
        this.map.forEach((value, key) => {
            exportData[key] = conflict[key] || value
        })
        // eslint-disable-next-line
        console.log(JSON.stringify(exportData, Object.keys(exportData).sort(), 2))
    }

    get = (name) => this.map.get(name)

    guessParentType = (type) => {
        if (type.endsWith('Connection')) {
            return type
        }
        return `${pluralize(type)}Connection`
    }

    guessChildType = (type) => {
        if (!type) {
            debugger
        }

        if (type.endsWith('Connection')) {
            return pluralize.singular(type.slice(0, -10))
        }
        return type
    }

    argsToString = (blob) => Object.keys(blob).map((key) => {
        const value = blob[key]
        return `${key}:${JSON.stringify(value)}`
    }).join(',')

    nest = (parent, fragment, condition, overrideArgs) => {
        const parentType = this.get(parent)

        /* eslint-disable no-param-reassign */
        if (parentType) {
            if (condition || overrideArgs) { // this is some messy if statements
                parent += '('
            }
            if (condition) {
                if (parent.endsWith('ById(')) {
                    parent += `${this.argsToString(condition)}`
                } else {
                    parent += `condition:{${this.argsToString(condition)}}`
                }
                const fragmentArray = fragment.split('\n')
                const keys = Object.keys(condition).filter((element) => !fragmentArray.includes(element))
                fragment += ['', ...keys].join('\n')
                if (overrideArgs) {
                    parent += `, ${overrideArgs})`
                } else {
                    parent += ')'
                }
            } else if (overrideArgs) {
                parent += `${overrideArgs})`
            }
            if (parentType.endsWith('Connection')) {
                return `${parent}{\nnodes{\n${fragment}\n}\n}`
            }
            return `${parent}{\n${fragment}\n}`
        }
        /* eslint-disable no-param-reassign */

        throw new Error(`Parent type ${parent} not found when nesting fragment ${fragment}`)
    }
}

export default new TypeMap()
