import axios from 'axios'

import TypeMap from './TypeMap.js'
import Cache from './Cache.js'
import QueryManager from './QueryManager.js'
import { createCacheContext } from './services/cache-context-service.js'

class Delv {
    constructor() {
        this.queries = new QueryManager()
        this.isReady = false
        this.queuedQueries = []
    }

    config = ({
        url, handleError, production, onReady, resolvers, typeConflicts,
    }) => {
        this.url = url
        this.handleError = handleError
        this.callback = onReady
        this.cache = new Cache(resolvers, typeConflicts)

        if (!production) {
            this.loadIntrospection()
        } else {
            TypeMap.loadTypes(production)
            this.callback()
            this.isReady = true
        }
    }

    // This method is used to extend Delv's typemap. See /platform/packages/vertalo-applications/README.md for more details.
    loadIntrospection = () => {
        axios.post(this.url, {
            query: `{
              __schema {
                types{
                  name
                  fields{
                    name
                    type{
                      name
                      ofType{
                        name
                      }
                    }
                  }
                }
              }
          }`,
        })
            .then((res) => {
                TypeMap.loadIntrospection(res.data.data)
                this.onReady()
            })
            .catch((error) => {
                throw new Error(
                    `Something went wrong while attempting making introspection query ${
                        error}`,
                )
            })
    }

    onReady = () => {
        this.isReady = true
        this.callback()
        this.queuedQueries.forEach((query) => {
            const cacheContext = createCacheContext(query)
            this.queryHttp({ cacheContext, ...query })
        })
        this.queuedQueries = undefined
    }

    post = (query, variables) => axios.post(this.url, {
        query,
        variables,
    })

    queryHttp = ({
        query,
        variables,
        onFetch,
        onResolve,
        onError,
        cacheProcess,
        cacheContext,
    }) => {
        if (!this.isReady) {
            this.queuedQueries.push({
                query,
                variables,
                onFetch,
                onResolve,
                onError,
                cacheProcess,
            })
            return
        }
        const queryId = this.queries.add(query, variables) // TODO add typenames on only some queries
        const promise = this.post(this.queries.addTypename(query), variables)
            .then((res) => {
                if (res.data.errors) {
                    if (this.handleError && this.handleError(res)) {
                        // error handled elsewhere
                    } else {
                        onError(res.data.errors)
                    }
                } else {
                    this.cache.add(res.data.data, cacheProcess, queryId, cacheContext)
                }
                return res
            })
            .then((res) => {
                if (!res.data.errors) {
                    try {
                        const data = this.cache.get(query, cacheProcess, queryId, cacheContext)
                        if (data && !data.error) {
                            onResolve(data)
                        } else {
                            onResolve(res.data.data)
                        }
                    } catch (error) {
                        // console.log('Error occured while tryin.g to load data from query ' + error.message)
                        onResolve(res.data.data)
                    }
                }
                return res
            })
            .catch((error) => {
                if (onError) {
                    if (error.response?.data.errors) {
                        onError(error.response?.data.errors)
                    } else {
                        throw error
                    }
                }
                if (this.handleError) {
                    this.handleError(error, true)
                }
            })
        onFetch(promise, {
            queryManager: this.queries,
            cache: this.cache,
        })
        this.queries.setPromise(query, variables, promise)
    }

    query = (options) => {
        // eslint-disable-next-line no-param-reassign
        options.cacheContext = createCacheContext(options)
        // query, variables, networkPolicy, onFetch, onResolve, onError
        switch (options.networkPolicy) {
        case 'cache-first':
            this.cacheFirst(options)
            break
        case 'cache-only':
            this.cacheOnly(options)
            break
        case 'network-only':
            this.queryHttp(options) // query, variables, onFetch, onResolve, onError
            break
        case 'network-once':
            this.networkOnce(options)
            break
        default:
            break
        }
    }

    cacheOnly = ({
        query, variables, cacheProcess, onResolve, cacheContext,
    }) => {
        const storedQuery = this.queries.includes(query, variables)
        onResolve(this.cache.get(query, cacheProcess, storedQuery && storedQuery.id, cacheContext))
    }

    cacheFirst = (options) => {
        const storedQuery = this.queries.includes(
            options.query,
            options.variables,
        )
        const cacheData = this.cache.get(
            options.query,
            options.cacheProcess,
            storedQuery && storedQuery.id,
            options.cacheContext,
        )

        let hasFullData = !!cacheData

        if (hasFullData) {
            Object.values(cacheData).forEach((value) => { // short term solution
                if (value === null) {
                    hasFullData = false
                }
            })
        }

        if (hasFullData) {
            options.onResolve(cacheData)
        } else {
            this.queryHttp(options)
        }
    }

    networkOnce = ({
        query,
        variables,
        onFetch,
        onResolve,
        onError,
        cacheProcess,
        cacheContext,
    }) => {
        const storedQuery = this.queries.includes(query, variables)
        if (storedQuery) {
            if (storedQuery.promise) {
                storedQuery.promise.then((res) => {
                    onResolve(res.data.data)
                    return res
                })
            } else {
                onResolve(
                    this.cache.get(
                        query,
                        cacheProcess,
                        storedQuery && storedQuery.id,
                        cacheContext,
                    ),
                )
            }
        } else {
            this.queryHttp({
                query,
                variables,
                onFetch,
                onResolve,
                onError,
                cacheProcess,
                cacheContext,
            })
        }
    }

    clearCache = () => {
        this.queries = new QueryManager()
        this.cache.clearCache()
    }
}

export default new Delv()
