import Row from './Row.js'
import Fragment from './Fragment.js'
import RowHeading from './RowHeading.js'
import OrderByHeader from './OrderByHeader.js'
import Card from './Card.js'

import './styles.scss'

export default {
    fragment: Fragment,
    row: Row,
    card: Card,
    rowHeading: RowHeading,
    orderByHeader: OrderByHeader,
}
