import numeral from 'numeral'
import moment from 'moment'

const doNothing = (input) => input

const email = (input) => {
    const trimmedInput = input.trim()

    if (!trimmedInput || !trimmedInput.match(/^.+@.+\..+$/)) {
        return { error: true }
    }
    return trimmedInput
}

const link = (input) => {
    if (input.startsWith('https://')) {
        return input
    }

    if (input.startsWith('http://')) {
        return input.replace('http', 'https')
    }

    return `https://${input}`
}

const number = (input) => numeral(input).format('0,0.00')

const integer = (input) => numeral(input).format('0,0')

const date = (input) => {
    const theDate = new Date(input)
    if (!input || `${theDate}` === 'Invalid Date') {
        return { error: true }
    }
    return moment(theDate).format('MM/DD/YYYY')
}

const blockchainAddress = (input) => {
    const formatted = input.match(/^(0x)?[0-9a-fA-F]{40}$/g)
    if (!formatted || formatted.length !== 1) {
        return { error: true }
    }
    return formatted[0]
}

export default {
    email,
    string: doNothing,
    link,
    number,
    date,
    boolean: doNothing,
    dropdown: doNothing,
    multiSelect: doNothing,
    blockchainAddress,
    color: doNothing,
    integer,
    twitterHandle: doNothing,
    password: doNothing,
    phone: doNothing,
    dropdownTypeahead: doNothing,
    checkbox: doNothing,
    textArea: doNothing,
}
