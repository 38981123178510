import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import Delv from 'delv/delv-react'
import Format from 'util/format/Format.js'
import Message from 'message/Message.js'
import Map from 'common/Map.js'
import Account from 'components/account/index.js'
import Round from 'components/round/index.js'
import Ledger from 'components/ledger/index.js'
import Asset from 'components/asset/index.js'
import Distribution from 'components/distribution/index.js'
import Holding from 'components/holding/index.js'
import AssetCard from './AssetCard.js'
import IssuerCard from './IssuerCard.js'
import TransactionCard from './TransactionCard.js'
import DashboardSection from './DashboardSection.js'
import DashboardHeader from './DashboardHeader.js'

import './styles.scss'

const holdingsFragment = `
    allHoldings{
        nodes{
            ${Holding.fragment.toString()}
        }
    }
`

const GET_DASHBOARD_DATA = {
    query: ({ accountId, roundStatus, accountType }) => {
        // Issuers see broker accounts, brokers see issuer accounts
        const type = accountType === 'issuer' ? 'broker' : 'issuer'
        const query = `{
            accountById(id: "${accountId}") {
                ${Account.fragment.toString()}
                holdings
                customersByVendorId {
                    nodes {
                        id
                    }
                }
            }
            allRounds(condition: {status: "${roundStatus}"}) {
                nodes {
                    ${Round.fragment.toString()}
                }
            }
            allTransactions(first: 5) {
                nodes {
                    id
                    ledgersByTransactionId {
                        nodes {
                            ${Ledger.fragment.toString()}
                        }
                    }
                }
            }
            allAccounts(condition: {type: "${type}"}) {
                nodes {
                    ${Account.fragment.toString()}
                    assetsByIssuerId {
                        nodes {
                            ${Asset.fragment.toString()}
                        }
                    }
                }
            }
            allAssets{
                nodes {
                    ${Asset.fragment.toString()}
                }
            }
            allDistributions {
                nodes {
                    ${Distribution.fragment.toString()}
                }
            }
            ${accountType === 'issuer' ? holdingsFragment : ''}
        }`

        return query
    },
    args: (props) => {
        const accountId = props.account.id
        const accountType = props.account.type
        const roundStatus = props.account.type === 'issuer' ? 'closed' : 'active'
        return {
            accountId,
            accountType,
            roundStatus,
        }
    },
    format: (data) => {
        const {
            allAssets,
            allAccounts,
            allTransactions,
            allRounds,
            accountById,
            allDistributions,
            allHoldings,
        } = data
        // eslint-disable-next-line
        allAssets.nodes = allAssets.nodes.filter((asset, index) => index < 3)

        // Limit number of accounts displayed
        // eslint-disable-next-line
        allAccounts.nodes = allAccounts.nodes.filter((asset, index) => index < 3)
        const rounds = allRounds.nodes.length
        const capital = `$${Format.number(accountById.holdings)}`
        const investors = accountById.customersByVendorId.nodes.length
        return {
            allAssets,
            allAccounts,
            allTransactions,
            countOfDistributions: allDistributions?.nodes.length,
            countOfHoldings: allHoldings?.nodes.length,
            rounds,
            capital,
            investors,
        }
    },
}

const DashboardContent = ({
    allAssets,
    allAccounts,
    allTransactions,
    countOfDistributions,
    countOfHoldings,
    rounds,
    capital,
    investors,
    account,
}) => {
    const isIssuer = account.type === 'issuer'
    return (
        <>
            <div className='dashboard-header'>
                <DashboardHeader
                    label={isIssuer ? 'Closed Rounds' : 'Active Rounds'}
                    text={rounds}
                    url='/assets'
                />
                <DashboardHeader
                    label='Capital Raised'
                    text={capital}
                    url='/capitalization'
                />
                <DashboardHeader
                    label='Investors Engaged'
                    text={investors}
                    url='/contacts'
                />
                <DashboardHeader
                    label='All Distributions'
                    text={countOfDistributions}
                    url='/distributions'
                />
                {
                    isIssuer && (
                        <DashboardHeader
                            label='All Holdings'
                            text={countOfHoldings}
                            url='/holdings'
                        />
                    )
                }
            </div>
            <div className='dashboard-body'>
                <div className='dashboard-body-row'>
                    <DashboardSection
                        url={isIssuer ? '/distributors' : '/issuers'}
                    >
                        {isIssuer ? 'Distributors' : 'Issuers'}
                        <div>
                            <Map
                                allAccounts={allAccounts}
                                arrayKey='allAccounts.nodes'
                            >
                                <IssuerCard route='distributor' />
                            </Map>
                        </div>
                    </DashboardSection>
                    <DashboardSection url='/assets'>
                        Assets
                        <div>
                            <Map
                                allAssets={allAssets}
                                arrayKey='allAssets.nodes'
                            >
                                <AssetCard hideType />
                            </Map>
                        </div>
                    </DashboardSection>
                </div>
                <div className='dashboard-body-row'>
                    <DashboardSection url='/messages'>
                        Investor messages
                        <div>This feature is not available</div>
                        <Message.container>
                            <Message.header />
                        </Message.container>
                    </DashboardSection>
                    <DashboardSection url='/transactions'>
                        Transactions
                        <Message.container>
                            <Message.header>
                                <Message.headerItem>
                                    Transaction
                                </Message.headerItem>
                                <Message.headerItem>
                                    Ledger entries
                                </Message.headerItem>
                            </Message.header>
                            <Map
                                allTransactions={allTransactions}
                                arrayKey='allTransactions.nodes'
                            >
                                <TransactionCard />
                            </Map>
                        </Message.container>
                    </DashboardSection>
                </div>
            </div>
        </>

    )
}

const BREADCRUMBS = [
    {
        display: 'Dashboard',
        link: '/dashboard',
        active: true,
    },
]

const Dashboard = (props) => (
    <>
        <Nav />
        <Container>
            <Breadcrumbs items={BREADCRUMBS} />
            <Delv.query {...GET_DASHBOARD_DATA} {...props}>
                <DashboardContent />
            </Delv.query>
        </Container>
        <Footer />
    </>
)

export default Dashboard
