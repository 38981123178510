import React from 'react'
import cx from 'classnames'
import './loading.scss'

export default ({ className = 'loading-dots', addClassName = '' }) => (
    <svg className={cx(className, addClassName)} xmlns='http://www.w3.org/2000/svg' version='1.0' viewBox='0 0 128 35' smiling='fake'>
        <g>
            <circle cx='17.5' cy='17.5' r='17.5' />
            <animate attributeName='opacity' dur='1s' begin='0s' repeatCount='indefinite' keyTimes='0;0.167;0.5;0.668;1' values='0.3;1;1;0.3;0.3' />
        </g>
        <g>
            <circle cx='110.5' cy='17.5' r='17.5' />
            <animate attributeName='opacity' dur='1s' begin='0s' repeatCount='indefinite' keyTimes='0;0.334;0.5;0.835;1' values='0.3;0.3;1;1;0.3' />
        </g>
        <g>
            <circle cx='64' cy='17.5' r='17.5' />
            <animate attributeName='opacity' dur='1s' begin='0s' repeatCount='indefinite' keyTimes='0;0.167;0.334;0.668;0.835;1' values='0.3;0.3;1;1;0.3;0.3' />
        </g>
    </svg>
)
